//Gallery.js
import { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import 'lazysizes'; // Import LazySizes library
import 'lazysizes/plugins/attrchange/ls.attrchange'; 


const PhotoGallery = ({ images }) => {
  useEffect(() => {
    // Initialize PhotoSwipe with both children and dataSource for correct order
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#gallery', // Referring to the gallery container
      children: 'a', // Using 'a' elements to trigger
      pswpModule: () => import('photoswipe'),
      dataSource: images.map((image) => ({
        src: image.src,
        width: image.width,
        height: image.height,
      })), // Ensure lightbox opens images in sorted order
    });

    // Register custom download button in the UI
    lightbox.on('uiRegister', () => {
      if (lightbox.pswp && lightbox.pswp.ui) {
        lightbox.pswp.ui.registerElement({
          name: 'download-button',
          order: 8,
          isButton: true,
          tagName: 'a',
          html: {
            isCustomSVG: true,
            inner: '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
            outlineID: 'pswp__icn-download',
          },
          onInit: (el, pswp) => {
            const anchorElement = el;
            anchorElement.setAttribute('download', '');
            anchorElement.setAttribute('target', '_blank');
            anchorElement.setAttribute('rel', 'noopener');
            pswp.on('change', () => {
              if (pswp.currSlide && pswp.currSlide.data && pswp.currSlide.data.src) {
                anchorElement.href = pswp.currSlide.data.src;
              } else {
                anchorElement.href = ''; // Fallback if src is undefined
              }
            });
          },
        });
      }
    });

    lightbox.init();

    return () => {
      lightbox.destroy(); // Cleanup on unmount
    };
  }, [images]);

  return (
    <div className="page_wrap is--bg-pink-light">
      <section className="section-hero">
        <div className="gallery-hero__contain u-container is--min-100svh u-vflex-stretch-top u-gap-medium">
          
          {/* Hero Content */}
          <div className="gallery-hero__content-wrp u-text-align-center u-vflex-center-top u-gap-small">
            <h1 className="gallery-hero__heading u-text-h1">Isa & Masha</h1>
            <p className="u-text-small">23. August 2024</p>
          </div>

          {/* CSS Grid Layout */}
          <div className="gallery-grid__wrp">
            <div id="gallery" className="gallery-grid__c-list">
              {images.map((image, index) => (
                <div key={index} className="gallery-grid__item">
                  <a
                    href={image.src}
                    data-pswp-width={image.width}
                    data-pswp-height={image.height}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={image.src}
                      alt={`Wedding Picture ${index}`}
                      className="lazyload thumbnail"
                      loading="lazy"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PhotoGallery;
