// src/components/PasswordProtect.js
import React, { useState } from 'react';

const PasswordProtect = ({ children }) => {
  const [enteredPassword, setEnteredPassword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);

  const correctPassword = process.env.REACT_APP_PASSWORD; // Fetch from .env

  const handleSubmit = (e) => {
    e.preventDefault();
    if (enteredPassword === correctPassword) {
      setIsAuthorized(true);
    } else {
      alert('Incorrect password');
    }
  };

  if (!isAuthorized) {
    return (
      <div className="password-protect-page">
        <h2>Please Enter Password</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
            placeholder="Enter Password"
            className="password-input"
          />
          <button type="submit" className="submit-btn">Submit</button>          
        </form>
      </div>
    );
  }

  return <>{children}</>;
};

export default PasswordProtect;
