// src/App.js
import "./App.css";
import PhotoGallery from "./components/Gallery";
import PasswordProtect from "./components/PasswordProtect";

// Image array with correct src paths
const images = [
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 001.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 002.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 003.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 004.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 005.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 006.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 007.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 008.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 009.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 010.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 011.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 012.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 013.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 014.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 015.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 016.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 017.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 018.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 019.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 020.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 021.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 022.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 023.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 024.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 025.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 026.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 027.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 028.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 029.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 030.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 031.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 032.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 033.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 034.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 035.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 036.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 037.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 038.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 039.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 040.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 041.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 042.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 043.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 044.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 045.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 046.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 047.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 048.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 049.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 050.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 051.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 052.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 053.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 054.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 055.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 056.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 057.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 058.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 059.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 060.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 061.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 062.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 063.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 064.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 065.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 066.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 067.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 068.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 069.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 070.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 071.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 072.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 073.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 074.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 075.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 076.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 077.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 078.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 079.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 080.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 081.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 082.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 083.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 084.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 085.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 086.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 087.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 088.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 089.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 090.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 091.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 092.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 093.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 094.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 095.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 096.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 097.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 098.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 099.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 100.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 101.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 102.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 103.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 104.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 105.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 106.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 107.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 108.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 109.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 110.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 111.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 112.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 113.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 114.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 115.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 116.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 117.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 118.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 119.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 120.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 121.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 122.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 123.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 124.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 125.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 126.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 127.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 128.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 129.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 130.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 131.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 132.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 133.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 134.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 135.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 136.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 137.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 138.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 139.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 140.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 141.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 142.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 143.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 144.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 145.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 146.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 147.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 148.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 149.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 150.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 151.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 152.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 153.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 154.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 155.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 156.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 157.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 158.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 159.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 160.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 161.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 162.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 163.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 164.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 165.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 166.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 167.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 168.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 169.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 170.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 171.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 172.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 173.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 174.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 175.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 176.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 177.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 178.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 179.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 180.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 181.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 182.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 183.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 184.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 185.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 186.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 187.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 188.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 189.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 190.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 191.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 192.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 193.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 194.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 195.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 196.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 197.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 198.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 199.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 200.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 201.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 202.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 203.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 204.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 205.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 206.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 207.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 208.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 209.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 210.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 211.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 212.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 213.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 214.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 215.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 216.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 217.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 218.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 219.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 220.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 221.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 222.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 223.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 224.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 225.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 226.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 227.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 228.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 229.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 230.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 231.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 232.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 233.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 234.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 235.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 236.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 237.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 238.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 239.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 240.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 241.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 242.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 243.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 244.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 245.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 246-0.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 247.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 248.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 249.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 250.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 251.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 252.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 253.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 254.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 255.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 256.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 257.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 258.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 259.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 260.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 261.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 262.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 263.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 264.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 265.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 266.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 267.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 268.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 269.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 270.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 271.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 272.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 273.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 274.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 275.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 276.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 277.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 278.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 279.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 280.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 281.webp",
    width: 800,
    height: 1200,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 282.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 283.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 284.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 285.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 286.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 287.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 288.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 289.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 290.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 291.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 292.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 293.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 294.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 295.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 296.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 297.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 298.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 299.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 300.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 301.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 302.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 303.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 304.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 305.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 306.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 307.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 308.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 309.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 310.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 311.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 312.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 313.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 314.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 315.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 316.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 317.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 318.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 319.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 320.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 321.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 322.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 323.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 324.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 325.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 326.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 327.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 328.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 329.webp",
    width: 1200,
    height: 800,
  },
  {
    src: "/images/isa-and-masha-wedding-2024_Photo 330.webp",
    width: 1200,
    height: 800,
  },
];

function App() {
  return (
    <PasswordProtect>
      <div className="App">
        <PhotoGallery images={images} />
      </div>
    </PasswordProtect>
  );
}

export default App;
